.ClientCard{
    background-color: rgb(176, 176, 176);
    height: 80px;
    width:180px;
    margin: 20px 30px;
    border: 2px solid #1B263B;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .5s;
    filter:grayscale(100%);
    cursor: pointer;
}


.ClientCard_img{
    width: 80%;
    height: 70%;
    object-fit: contain; 
}



.ClientCard:hover{
    filter:grayscale(0%);
}