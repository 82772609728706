.TZcontainer{
    background-color: rgb(0, 254, 169);
    height: auto;
   
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 9%;
   
}
@media(max-width:500px){
    .TZcontainer{
        margin: 3%;
     
    }
}