.procedure{
   width: 100%;
    background-color: #1B263B;
    color: white; 
    border-radius: 5px;
    padding: 20px 20px;
    font:16px  Roboto, Helvetica,Arial, sans-serif;

}

.procedure-step{
    display: flex;
   padding: 20px;
    align-items: center;
    text-align: left;
}

.procedure-step-count{
    border: 2px solid white;
    border-radius: 50%;
    
    margin: 20px 20px 20px 0px;
}
.procedure-step-count> h3 {
    margin: 15px;
}
.procedure-step-description{
    text-align: justify;
}


.procedure-step:hover .procedure-step-count {
    border: 2px solid #ff135a;
    transition: 0.5s;
    transform: scale(1.1);

}
.procedure-step:hover .procedure-step-Heading {
    color: #ff135a;
    transition: 0.5s;
    border-bottom:3px solid #ff135a;
}


@media(max-width:570px){
    .procedure-step{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
       
    }
    .procedure{
        color: white; 
        /* border-radius: 0px; */
        padding: 15px 5px;
    }
    .procedure-step-count{
        /* border: 2px solid rgb(240, 0, 0); */
        border-radius: 50%;
        margin:10px 0px 10px 0px ;
    }
    
}

